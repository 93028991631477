@import '@scss/service';
$mob: 640px;
.reminder {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1100;
  color: $white;

  &__container {
    display: flex;
    box-sizing: border-box;
    border-radius: $border-radius;
    box-shadow: $box-shadow; //3px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: $gray;
    // background: transparent url('/images/reminder/content-background.jpg') center / cover no-repeat;

    width: 95%;
    height: 90%;
    max-width: 800px;
    min-width: 238px;
    max-height: 600px;
    min-height: 271px;
    position: relative;
  }

  &__close {
    background: transparent url('/theme/icons/icons_30x30.svg') 0 -30px no-repeat;
    &_white {
      background: transparent url('/theme/icons/icons_30x30.svg') 0 -90px no-repeat;
    }
    border: none;
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 30px;
    top: 10px;
    right: 10px;
    transition: transform 0.2s;

    &:hover {
      transform: rotate(90deg);
    }
  }
}
