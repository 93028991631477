@import '@scss/service';

$tab: $md;
$mob: $sm;
$crusoeColor: #014701;

.reminder {
  &__content {
    display: flex;
    position: relative;
    box-sizing: border-box;
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
    line-height: normal;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    background-color: $crusoeColor;
    color: $orange;
    @include respond($mob) {
      flex-direction: column;
    }

    &_result {
      background: transparent
        url('/images/reminder/hollydays-happy-draw/confetti.png') right / cover
        no-repeat;
      background-color: $crusoeColor;
    }

    .bow {
      max-width: 60%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      @include respond($mob) {
        max-width: 75%;
        top: -0.75em;
        left: -1.55em;
      }
    }
  }

  .imgBlock {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    height: 68%;
    background: transparent
      url('/images/reminder/hollydays-happy-draw/garland.png') left top /
      contain no-repeat;
    @include respond($mob) {
      width: 100%;
      height: 50%;
      background-position-y: center;
    }

    .imgItem {
      @include respond($mob) {
        max-width: 260px;
      }
    }

    &_result {
      background: none;
    }
  }

  .txtBlock {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    padding: 0 83px 0 18px;
    row-gap: 1.5em;
    z-index: 1;
    @include respond($mob) {
      width: 100%;
      height: 50%;
      padding: 0 1em 1em;
      row-gap: 0.75em;
      justify-content: flex-end;
      max-width: 350px;
    }
  }

  &__header {
    font-size: 2.625em;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 100%;
    @include respond($mob) {
      font-size: 2em;
    }
    br {
      display: inline;
      content: ' ';
      @include respond($mob) {
        display: block;
      }
    }
  }

  &__middle {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 113%;
    text-align: center;
    color: $white;
    @include respond($mob) {
      font-size: 1.25em;
    }

    p {
      margin-top: 12px;
      color: $orange;
      font-size: 2.333em;
      font-weight: 400;
      font-family: 'Kaushan Script', cursive;
      line-height: 92.5%;
      @include respond($mob) {
        font-size: 2em;
        margin-top: 4px;

        br {
          display: none;
        }
      }
    }

    &_result {
      @include respond($mob) {
        font-size: 1.25em;
      }
    }
  }

  &__footnote {
    font-size: 1em;
    text-decoration: underline;

    :global .reminder__number {
      position: relative;
    }
    :global .reminder__number_quote {
      &:before {
        content: '*';
        left: 0.1em;
        position: relative;
      }
    }
  }
}

.button__wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 270px;
  text-align: center;
  @include respond($mob) {
    margin-top: 0.5em;
  }
}
.button {
  // @extend %button;
  @extend %button_beauty_params;
  font-size: 26px;
  font-weight: 700;
  padding: 0.73em;
  height: 76px;
  width: 100%;
  max-width: 270px;
  border-radius: 5px;
}

.button_footnote.button_footnote {
  color: $orange;
  font-size: 1em;
  text-decoration: underline;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  font-weight: 400;
  width: auto;
  max-width: 270px;

  &:hover,
  &:hover:not(:disabled) {
    background: none;
    color: $orange;
    font-weight: 700;
  }

  @include respond($mob) {
    margin-top: -0.5em;
  }
}

//--- new styles ---

.banner {
  background: white;
  width: 100%;
  height: 100%;
  min-height: 600px;
  padding: 16px;
  border-radius: 12px;

    .mainContent {
      background: url('/images/main-page/cars-banner/sparks.png') no-repeat bottom;
      background-size: contain;
      position: relative;
      @include respond($mob) {
        background: url('/images/main-page/cars-banner/sparks-left.png') no-repeat top left;
        background-size: contain;
      }

      .mainTextWrap {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding: 0;
        position: relative;
        left: -20px;

        @include respond($mob) {
          padding: 90px 0 0;
          left: 8px;
        }
        .numberGradient {
          width: min-content;
          font-weight: 800;
          line-height: 100%;
          vertical-align: middle;
          font-size: 233px;
          background: linear-gradient(to left, #FEC601, #CE1127, #2A70C8);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include respond($mob) {
            font-size: 190px;
          }
        }
        .mainText {
          color: #484951;
          padding: 4px 10px;
          white-space: break-spaces;
          font-size: 43px;
          line-height: 54px;
          @include respond($mob) {
            font-size: 34px;
            line-height: 130%;
          }
        }
      }

      .bubbleText {
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 174px;
        height: 174px;
        border-radius: 50%;
        background: #CE1127;
        transform: rotate(20deg);
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #fff;

        @include respond($tab) {
          top: 0;
          right: 50px;
        }
        @include respond($mob) {
          width: 125px;
          height: 125px;
          font-size: 12px;
          top: 20px;
          right: 23px;
        }
      }

      .img {
        position: relative;
        z-index: 1;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -25px;
        @include respond($mob) {
        }
      }
    }
    .buttonWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      top: -44px;
      .button {
        border-radius: 8px;
        background: linear-gradient(281.8deg, #FEC601 12.63%, #CE1127 49.92%, #2A70C8 92.33%);
        border: none;
        font-weight: 700;
        font-size: 32px;
        line-height: 100%;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        height: auto;
        width: auto;
        max-width: unset;
        padding: 24px 120px;
        @include respond($mob) {
          padding: 24px 50px;
        }
      }
      .buttonComment {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: black;
        padding: 16px;
        text-align: center;
      }
    }
}