@import '@scss/service';

.reminder {
  &__content {
    background: transparent url('/images/reminder/content-background.jpg')
      center / cover no-repeat;
    box-sizing: border-box;
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 2em;
    line-height: normal;
    position: relative;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    padding: 1em;

    @include respond(sm) {
      font-size: 1.6em;
    }

    @include respond(md, lg) {
      font-size: 2.5em;
      padding: 0.5em;
      width: 100%;
    }

    @include respond(xl, true) {
      font-size: 3em;
      padding: 1.5em;
    }
  }

  &__header {
    font-size: 0.63em;
  }

  &__text {
    margin-bottom: 0.4em;

    :global .discount {
      display: block;
    }
    :global .discount__number {
      color: $orange;
      font-weight: bold;
      font-size: 1.96em;
      position: relative;
    }
    :global .discount__number_quote:before {
      content: '*';
      font-size: 0.4em;
      left: 0.2em;
      position: relative;
      top: -1em;
    }

    :global .description {
      color: $orange;
      font-weight: bold;
      text-transform: uppercase;

      span {
        font-size: 1.96em;
      }
    }
  }

  & &LoanButton {
    font-size: 24px; //0.6em;
    width: 80%;
    color: $gray-dark;
    max-width: 486px;
    margin: 0 auto;
    padding: 0.8em 0;

    @include respond(xs) {
      max-width: 240px;
    }

    &:hover {
      background: linear-gradient(#eabf2e, #e8b503 45%, #cea114 55%, #ce9c02);
    }
  }

  &__footnote {
    font-size: 0.7em;
    margin-top: 1em;

    @include respond(sm, true) {
      font-size: 20px;
    }

    :global .reminder__number {
      position: relative;
    }
    :global .reminder__number_quote {
      &:before {
        content: '*';
        left: 0.1em;
        position: relative;
      }
    }
  }
}
