@import '@scss/service';

.reminder {
  &__content {
    background-color: #4175c3;
    box-sizing: border-box;
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
    display: flex;
    line-height: normal;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 64px 8px;
    @include respond(md) {
      flex-direction: column;
      padding: 0 2.5em 1.3em;
    }
  }

  .imgBlock {
    width: 48%;
    @include respond(md) {
      width: 100%;
      height: 50%;
      max-height: 303px;
      max-width: 265px;
    }
  }

  .txtBlock {
    display: flex;
    flex-direction: column;
    width: 52%;
    align-items: center;
    padding: 0 2em 0 0;
    z-index: 1;
    @include respond(md) {
      width: 100%;
      height: 50%;
      padding: 0;
      justify-content: flex-end;
    }
  }

  &__header {
    font-size: 3em;
    color: $orange;
    font-weight: 700;
    line-height: 1.75em;
    cursor: pointer;
    @include respond(md) {
      font-size: 2.25em;
    }
  }

  &__text {
    font-size: 2em;
    font-weight: bold;
    margin-top: 0.625em;
    cursor: pointer;
    @include respond(md) {
      font-size: 1.25em;
      margin-top: 0.4em;
    }
  }

  .copyButton {
    background: none;
    border: none;
    margin: 0 0.5em;
    vertical-align: sub;
    padding: 0;
    &:hover {
      background: none;
    }

    svg {
      width: 32px;
      height: 32px;
      &:hover {
        path {
          fill: #ebebeb;
        }
      }
      @include respond(md) {
        width: 22px;
        height: 22px;
      }
    }
  }

  & &LoanButton {
    font-size: 24px; //0.6em;
    width: 80%;
    color: $white;
    max-width: 486px;
    margin: 1.75em auto 0;
    padding: 0.8em 0;
    max-width: 270px;

    @include respond(md) {
      margin: 0.65em auto 0;
    }
    @include respond(xs) {
      max-width: 240px;
    }
  }

  &__footnote {
    font-size: 0.7em;
    margin-top: 1em;

    @include respond(sm, true) {
      font-size: 20px;
    }

    :global .reminder__number {
      position: relative;
    }
    :global .reminder__number_quote {
      &:before {
        content: '*';
        left: 0.1em;
        position: relative;
      }
    }
  }
}
